import React from 'react';

import {Helmet} from "react-helmet";
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/projects.css';

const Projects = () => (
  <Layout>
    <SEO
      title="Projects"
      keywords={[`Hashir`, `Ghouri`, `Portfolio`, `Projects`]}
    />

    <Helmet>
      <link rel="stylesheet" href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"/>
    </Helmet>

  
      <div className="full-container">
        <h1 className="head mb-8">Projects</h1>
          <div className="gallery">

            <div className="gallery-item">
              <a href="https://crwn-21.netlify.app/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1608820279/Crwn-hashcode_lxrt7a.jpg" 
              alt="Crwn React E-commerce App" /></a>
              <p className="title">Crwn E-commerce App<span className='info'><a href="https://github.com/Hashcodepk/crwn-clothing" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Developed an e-commerce web application with reactJS & state of the app is managed with Redux. Used Firebase to store data and Firebase auth for registration and authentication.
              </div>
            </div>

            <div className="gallery-item ">
              <a href="https://covid-pak.netlify.app/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1608820279/Covid-tracker_qlkbzm.jpg" 
              alt="Covid-19 Tracker" /></a>
              <p className="title">Covid-19 Tracker<span className='info'><a href="https://github.com/Hashcodepk/covid-pak" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Covid tracker is an progressive web application built with reactJS to get realtime data of covid-19 in Pakistan. This is an single page application fast and minimal that fetch data from Covid API.
              </div>
            </div>

            <div className="gallery-item ">
              <a href="https://hash-weather.netlify.app/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1608820279/Weather_App_l7xupy.jpg" 
              alt="Live Weather PWA" /></a>
              <p className="title">Live Weather PWA<span className='info'><a href="https://github.com/Hashcodepk/personal-weather" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Fully functional mobile first progressive web app built with vanilla javaScript and accuweather API to get detailed weather conditions with forcasts. You can search for any cities with accuweather autocomplete API.
              </div>
            </div>

            <div className="gallery-item ">
              <a href="https://aksupplier.com/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1608823003/Ak_Supplier_lyily0.jpg" 
              alt="AK Supllier Online" /></a>
              <p className="title">AK Supllier Online<span className='info'><a href="#" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Built this e-commerce site with Wordpress and woocommerce for client.
              </div>
            </div>

            <div className="gallery-item ">
              <a href="https://smart-face-detection-brain.herokuapp.com/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143789/Smart_Brain_pxjlu3.jpg" 
              alt="Smart Brain Project" /></a>
              <p className="title ">Smart Brain Project<span className='info'><a href="https://github.com/Hashcodepk/Smart-brain" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              This is an full stack web app, Front-end built with ReactJS & Back-end with NodeJS using express and postgreSQL. Smart Brain is a Face Recognition app powered with Clarifai API & authentication with JSON Web Tokens.
              </div>
            </div>

            <div className="gallery-item ">
            <a href="https://hashcodepk.github.io/RoboFriends-Redux/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143788/Robo_Friends_lvonqc.jpg"
              alt="Robo Friends App" /></a>
              <p className="title">Robofriends App<span className='info'><a href="https://github.com/Hashcodepk/RoboFriends-Redux" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Built wiht React and Redux with search feature using API.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://hashcodepk.github.io/Background-Gradient-Generator/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143789/Gradient_Generator_svsrxq.jpg" 
              alt="CSS Gradient Generator" /></a>
              <p className="title">CSS Gradient Generator<span className='info'><a href="https://github.com/Hashcodepk/Background-Gradient-Generator" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              This is an app for generating CSS gradient background using Vanilla Javascript. Simple & useful. 
              </div>
            </div>

            <div className="gallery-item">
            <a href="http://red-drug.com/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143788/Red_Drug_fqewoh.jpg" 
              alt="Red Drug Website" /></a>
              <p className="title">Red-Drug<span className='info'><a href="#" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Modern website for Digital Agency with Ajax Loading.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://zero-to-mastery.github.io/starwars-spaceships/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143788/Star_wars_mivj5m.jpg" 
              alt="Starwars App" /></a>
              <p className="title">Starwars App<span className='info'><a href="https://github.com/zero-to-mastery/starwars-spaceships" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              I created this open source project for Star Wars fans using starwars API.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://hashcodepk.github.io/natours-front/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143790/Natours_jjjgtk.jpg" 
              alt="Natours Project" /></a>
              <p className="title">Natours Project<span className='info'><a href="https://github.com/Hashcodepk/natours-front" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
              <div className='tooltip'>
              Front-end modern design for travel agency with HTML5 & SCSS.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://hashcodepk.github.io/wikiSearch/" target="_blank" rel="noopener noreferrer">
          <img className="gallery-image" 
          src="https://res.cloudinary.com/hashcode/image/upload/v1612177588/searchMe_hgvi58.jpg" 
          alt="SearchMe" /></a>
          <p className="title">SearchMe - wikiSearch<span className='info'><a href="https://github.com/Hashcodepk/wikiSearch" target="_blank" rel="noreferrer"><img src="https://pngimg.com/uploads/github/github_PNG40.png"/></a></span></p>
          <div className='tooltip'>
          Wikipedia search app developed with Vanilla Javascript using Wikipedia API and Web Content Accessibility Guidelines.
          </div>
        </div>

                <div className="gallery-item">
                <a href="https://www.almueedmedical.com/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143790/Al_Mueed_Medical_sayce6.jpg" 
              alt="Al-Mueed Medical Website" /></a>
              <p className="title">Al-Mueed Medical</p>
              <div className='tooltip'>
              Al-Mueed Medical Center website with appointment booking system built with PHP & Wordpress.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://www.sendmeworldwide.com.pk/en/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143789/Send_Me_Worldwide_uepqzn.jpg" 
              alt="Send Me Worldwide" /></a>
              <p className="title">Sendme Worldwide</p>
              <div className='tooltip'>
              SendMe Worldwide International Courier website with tracking system.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://sendme.pk/en/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143789/Send_Me_Shop_fm5rdy.jpg" 
              alt="Send Me Shop" /></a>
              <p className="title">Sendme Online Shop</p>
              <div className='tooltip'>
              Sendme Worldwide Online shop powered by WooCommerce.
              </div>
            </div>

            <div className="gallery-item">
            <a href="https://www.hashcode.pk/" target="_blank" rel="noopener noreferrer">
              <img className="gallery-image" 
              src="https://res.cloudinary.com/hashcode/image/upload/v1556143791/Hashcode_blog_m7sf2w.jpg" 
              alt="Hashcode.pk Blog" /></a>
              <p className="title">Hashcode Blog</p>
              <div className='tooltip'>
              Personal site for blogging.
              </div>
            </div>

       </div>
        
      </div>
   
  </Layout>
)

export default Projects
